import { PairingRepairLogList } from '../components/Pairing/RepairLog';

function GlobalPairingRoundRepairLogsRoute() {
  return <PairingRepairLogList />;
}

export const Component = GlobalPairingRoundRepairLogsRoute;

export function clientLoader() {
  return null;
}
